// Variables

$min-width: 992px !default;
$max-width: 991px !default;

$white: #fff !default;
$white-2: #f0f0f0 !default;
$white-0-1: rgba(255, 255, 255, 0.04) !default;
$white-0-4: rgba(255, 255, 255, 0.4) !default;
$white-0-5: rgba(255, 255, 255, 0.5) !default;

$standard-dark: #212529 !default;
$gray: #818181 !default;
$gray-dark: #666 !default;
$gray-100: #f8f8f8 !default;
$gray-light-1: #a3a3a3 !default;
$gray-footer: #5c5c5c !default;
$gray-dark-2: #495057 !default;
$gray-darker: #343434 !default;
$gray-darkest: #292929 !default;

// gfbio colors
$gfbio-blue: #345aa2 !default;
$gfbio-blue-0-6: rgba(52, 90, 162, 0.6) !default;
$gfbio-blue-0-8: rgba(52, 90, 162, 0.8) !default;
$gfbio-blue-0-8-fulltone: #476aab !default;
$gfbio-blue-0-1: rgba(52, 90, 162, 0.1) !default;

$gfbio-light-blue: #3cace4 !default;
$gfbio-light-blue-0-8: rgba(60, 172, 228, 0.8) !default;
$gfbio-light-blue-0-6: rgba(60, 172, 228, 0.6) !default;
$gfbio-light-blue-0-1: rgba(60, 172, 228, 0.1) !default;

$gfbio-green: #81b248 !default;
$gfbio-green-0-9: rgba(129, 178, 72, 0.9) !default;
$gfbio-green-0-8: rgba(129, 178, 72, 0.8) !default;
$gfbio-green-0-7: rgba(129, 178, 72, 0.7) !default;
$gfbio-green-0-6: rgba(129, 178, 72, 0.6) !default;
$gfbio-green-0-3: rgba(129, 178, 72, 0.3) !default;
$gfbio-green-0-1: rgba(129, 178, 72, 0.1) !default;

// dda colors
$dda-blue: #0061a1;
$dda-highlight: rgb(246, 169, 36);
$dda-green: rgb(0, 117, 92);
$dda-grey: rgb(163, 171, 177);
$dda-lightgrey: rgb(209, 214, 218);
$dda-red: rgb(207, 0, 61);
$dda-yellow: rgb(255, 225, 68);

$primary: $dda-blue !default;

// $primary: $gfbio-green !default;

$contrast-1: #ff8362 !default;
$contrast-2: #ff6339 !default;
$error-red: #dc3545 !default;
