/*
font-family: 'Nunito Sans', sans-serif;
font-family: 'Open Sans', sans-serif;
font-family: 'Raleway', sans-serif;
font-family: 'Roboto Slab', serif;
font-family: 'Rubik', sans-serif;
*/

@mixin nunito() {
  font-family: 'Nunito Sans', sans-serif;
}

@mixin rubik() {
  font-family: 'Rubik', sans-serif;
}

@mixin open-sans() {
  font-family: 'Open Sans', Arial, sans-serif;
}

@mixin lato() {
  font-family: 'Lato', sans-serif;
}

@mixin raleway() {
  font-family: 'Raleway', sans-serif;
}

@mixin roboto-slab() {
  font-family: 'Roboto Slab', serif;
}


/* -------------------------------------------------------------------------- */

@mixin sans-serif-font() {
  @include open-sans();
}

@mixin header-sans-serif-font() {
  @include raleway();
}

@mixin sans-serif-font-2() {
  @include raleway();
}

@mixin sans-serif-font-3() {
  @include open-sans();
}

@mixin serif-font() {
  font-family: 'Roboto Slab', serif;
}

@mixin transition-all() {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
}

@mixin background-cover() {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}